import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CommunityPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Community"}
              tagline={""}
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
                numToSelect: 1,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <p>
                Smoothie Q is committed to community service and giving back in
                means of feeding the hungry, families who need assistance due to
                disabilities, Orphans and more. We are not limited to just the
                community we are located, but any community. Every Friday,
                Smoothie Q donates a portion of all of their proceeds between
                the community they are in, Joni & Friends, and The Life House.
                Below you can read about the organizations that they are
                committed to.
              </p>
              <p>
                1. The community that we are in, is the lower Pottsgrove
                township. We serve Berks and Montgomery County. It is important
                to us to ensure that no one in our neighborhood goes hungry.
                During the week there is a drop box where our guest can drop a
                card in them with a family’s name that is needing assistance
                with food, we pull a card every Friday at closing to get the
                family’s name in need. At the end of the month when the funds
                are allocated, we feed four families that are need for food by
                way of gift cards to their local grocery store. If you want to
                get involved you can donate to this cause by visiting Smoothie Q
                and purchase anything on their menu as well as drop a name of a
                family that you know is in need of assistance with food in the
                box available in the store.
              </p>
              <p>
                <span>
                  2. Joni and Friends is built on Biblical truth and the
                  foundation of Jesus Christ. Since 1979, they have been
                  advancing disability ministry and changing the church and
                  communities around the world. The Joni and Friends
                  International Disability Center (IDC) serves as the
                  administrative center for ministry programs and locations
                  across the United States which provide outreach to thousands
                  of families impacted by disability around the globe. They
                  present the Gospel of Jesus Christ through all their programs
                  around the world. They train, disciple, and mentor people
                  affected by disability to exercise their gifts of leadership
                  and service in their churches and communities. They energize
                  the church to move from lack of awareness of people impacted
                  by disability to including everyone into the fabric of
                  worship, fellowship, and outreach. You can see more about them
                  at&nbsp;
                </span>
                <a
                  href={"https://www.joniandfriends.org/about/"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  https://www.joniandfriends.org/about/
                </a>
              </p>
              <p>
                <span>
                  3. LIFE HOUSE -Learning In a Family Environment provides
                  parent education services in a natural home environment and
                  provides emergency resources for families in crisis. Children
                  in foster care, benefit from this organization in a massive
                  way. To find out more about them you can check out their FB
                  page at&nbsp;
                </span>
                <a
                  href={
                    "https://www.facebook.com/LIFE-House-Reading-Berks-1797476987023250/"
                  }
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  www.facebook.com/LIFE-House-Reading-Berks-1797476987023250/
                </a>
              </p>
              <p>
                <span>4.&nbsp;</span>
                <a
                  href={"https://www.ceworks.faith/"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  Christian Endeavor
                </a>
                <span>&nbsp;exists to Empower Youth&nbsp;</span>
                <span className={"in-custom"}>IN</span>
                <span>
                  &nbsp;Ministry through its discipleship strategy, coaching,
                  resource development, and network interaction in forty-three
                  countries and across the United States. With over 140 years of
                  experience, CE exists to inspire, equip, and encourage
                  churches to Biblically develop youth as Christ-centered
                  leaders.
                </span>
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Community" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
